
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import { lucasButtonStyle } from "../components/slideshows/LucasSlideshow"

import logoSv from "../images/slideshow/lucas/Lucas_Logo_Sve.svg"
import logoEng from "../images/slideshow/lucas/Lucas_Logo_Eng.svg"
import headingSv from "../images/slideshow/lucas/Lucas_Rubrik_Sve.svg"
import headingEng from "../images/slideshow/lucas/Lucas_Rubrik_Eng.svg"
import forwardBlueIcon from "../images/slideshow/forward-blue.svg"
import coverImagePortr from "../images/slideshow/lucas/NW_Lucas_Bild_Portr_1.jpg"
import coverImageLands from "../images/slideshow/lucas/NW_Lucas_Bild_Lands_1.jpg"

const Wrapper = styled.div`
  min-height: 100%;
  background: #dd7400;
`

const SlideshowCover = styled.div`
  display: grid;
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100% - 9.225rem);

  .logo,
  .heading,
  picture,
  .background-image {
    display: block;
    width: auto;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
  }

  .logo {
    width: 100%;
  }

  .background-image {
    object-fit: contain;
    width: 100%;
    height: auto;
  }

  > div:nth-child(3),
  picture {
    display: flex;
  }

  @media (orientation: portrait) {
    top: 3rem;
    grid-template-rows: 27% 8% 65%;

    > div:nth-child(1) {
      padding-bottom: 1.25rem;
    }

    > div:nth-child(3) {
      padding-top: 2rem;
    }

    .logo,
    .heading,
    picture {
      max-width: calc(100% - 5rem);
    }
  }

  @media (orientation: landscape) {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - 16vw);
    height: 0;
    padding-top: 44.78%;
    margin: auto;

    > div {
      position: absolute;
    }

    > div:nth-child(1) {
      top: 1.2rem;
      left: 0;
      width: calc(45% - 1.5rem);
    }

    > div:nth-child(2) {
      top: calc(47% + 1.2rem);
      left: 2.2rem;
      width: calc(45% - 4.9rem);
    }

    > div:nth-child(3) {
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      max-width: 55%;
      height: auto;
      max-height: 100%;
    }
  }
`

const StartButton = styled(Link)`
  ${lucasButtonStyle}
  position: absolute;
  z-index: 2;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  transform: translateX(-50%);

  @media (orientation: portrait) {
    bottom: -4rem;
    left: 50%;
    transform-origin: center left;
  }

  @media (orientation: portrait) and (min-height: 768px) {
    bottom: -5rem;
  }

  @media (orientation: landscape) {
    bottom: 4.6%;
    left: 22.5%;
    transform-origin: bottom left;
  }

  @media (min-width: 768px) and (min-height: 620px) {
    transform: scale(1.3) translateX(-50%);
  }

  @media (min-width: 1100px) and (min-height: 620px) {
    transform: scale(1.6) translateX(-50%);
  }
`

export default function LucasStartPageTemplate({
  pageContext: { lang, pagePath },
}) {
  const intl = useIntl()
  const isEng = lang !== "sv"

  return (
    <Wrapper>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={intl.formatMessage({ id: "lucasSlideshowTitle" })}
        image={{
          url: coverImagePortr,
          alt: intl.formatMessage({ id: "lucasSlideshowCoverImageAltText" }),
          width: 821,
          height: 792,
        }}
      />
      <Header
        lang={lang}
        room={3}
        color="#dd7400"
        isTransparent
      />
      <SlideshowCover>
        <div>
          <img
            src={isEng ? logoEng : logoSv}
            alt={intl.formatMessage({ id: "lucasSlideshowTitle" })}
            className="logo"
          />
        </div>
        <div>
          <img
            src={isEng ? headingEng : headingSv}
            alt={intl.formatMessage({ id: "lucasSlideshowHeading" })}
            className="heading"
          />
        </div>
        <div>
          <picture>
            <source media="(orientation: portrait)" srcSet={coverImagePortr} />
            <source media="(orientation: landscape)" srcSet={coverImageLands} />
            <img
              src={coverImagePortr}
              alt={intl.formatMessage({ id: "lucasSlideshowCoverImageAltText" })}
              className="background-image"
            />
          </picture>
        </div>
        <StartButton to={`/${lang}/${pagePath}/1`} aria-label={intl.formatMessage({ id: "startSlideshow" })}>
          <span>{intl.formatMessage( { id: "comeAlong" })}<img src={forwardBlueIcon} alt="" role="none" /></span>
        </StartButton>
      </SlideshowCover>
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}
